import webserviceWrapper from "../helpers/webserviceWrapper";

export const TrackingToolService = {
    GetAllTrackingToolDataByClient,
    GetAllTrackingToolFilters,
    SaveCopyPastedRowData,
    DownloadPOFile,
    DownloadTrackingToolTemplate,
    ImportPO,
    SaveHazmatItemsToTrackingTool,
    GetImportedFilesData,
    GetImportPODropdownData,
    SendMDSDoCRequest,
    GetTrackingToolData,
    GetPOFileDetails,
    EditTrackingToolData,
    UpdateOtherValueStatus,
    GetLineItemHistory,
    AddMDSDoCData,
    DeleteTrackingToolDocData,
    SaveDocumentStatus,
    ReSendMDSDoCForCorrection,
    DownloadPOReadableFiles,
    DownloadTrackingToolData,
    DownloadAllTrackingToolData,
    GetAllHazmatFromDB,
    AddHazmatDataToDB,
    CheckIfHazmatPresent,
    DeleteTTLineItemData,
    CheckIfSupplierExist,
    GetAllOOCLApiData,
    DeleteOOCLApiData,
    DownloadApiImport
};

function GetAllTrackingToolDataByClient(clientID, userID, clientUserId, userTypeId) {
    return webserviceWrapper.get(`/TrackingTool/GetAllTrackingToolDataByClient?clientID=${clientID}&userID=${userID}&clientUserId=${clientUserId}&userTypeId=${userTypeId}`);
}

function GetAllTrackingToolFilters(userTypeId, userID, clientUserId) {
    let response = webserviceWrapper.get(`/TrackingTool/GetAllTrackingToolFilters?userTypeId=${userTypeId}&userID=${userID}&clientUserId=${clientUserId}`);
    return response;
}

function SaveCopyPastedRowData(pastedList) {
    return webserviceWrapper.post(
        `/TrackingTool/SaveCopyPastedRowData`,
        pastedList,
        600000
    );
}

function DownloadPOFile(ttIDList) {

    return webserviceWrapper.post(
        `/TrackingTool/DownloadPOFile`,
        ttIDList,
        600000
    );
}

function DownloadTrackingToolTemplate() {
    return webserviceWrapper.get(`/TrackingTool/DownloadTrackingToolTemplate`);
}

/* <summary>
date: 14/12/2022
Name: AS
description: service api call to import PO file
<summary> */
function ImportPO(userData) {
    return webserviceWrapper.postWithHeader(
        `/TrackingTool/ImportPO`,
        userData,
        { "Content-Type": "multipart/form-data" },
        1800000
    );
}

/* <summary>
date: 16/12/2022
Name: AS
description: service api call to save hazmat items to tracking tool
<summary> */
function SaveHazmatItemsToTrackingTool(userData) {
    return webserviceWrapper.postWithHeader(
        `/TrackingTool/SaveHazmatItemsToTrackingTool`,
        userData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}


function GetImportedFilesData(clientID, loggedInUserID) {
    return webserviceWrapper.get(`/TrackingTool/GetImportedFilesData?clientID=${clientID}&userID=${loggedInUserID}`);
}

function GetImportPODropdownData(loggedInUserID) {
    return webserviceWrapper.get(`/TrackingTool/GetImportPODropdownData?userID=${loggedInUserID}`);
}

function SendMDSDoCRequest(ttData) {
    return webserviceWrapper.post(
        `/TrackingTool/SendMDSDoCRequest`,
        ttData,
        600000
    );
}

function GetPOFileDetails(poImportedFileId) {
    return webserviceWrapper.get(`/TrackingTool/GetPOFileDetails?poImportedFileId=${poImportedFileId}`);
}


function GetTrackingToolData(ttID) {
    return webserviceWrapper.get(`/TrackingTool/GetTrackingToolData?trackingToolID=${ttID}`);
}

function EditTrackingToolData(ttData) {
    return webserviceWrapper.postWithHeader(
        `/TrackingTool/EditTrackingToolData`,
        ttData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}

function UpdateOtherValueStatus(ttData) {
    return webserviceWrapper.post(
        `/TrackingTool/UpdateOtherValueStatus`,
        ttData,
        60000
    );
}

function GetLineItemHistory(ttID) {
    return webserviceWrapper.get(`/TrackingTool/GetLineItemHistory?trackingToolID=${ttID}`);
}

function AddMDSDoCData(mdsdocData) {
    return webserviceWrapper.postWithHeader(
        `/TrackingTool/AddMDSDoCData`,
        mdsdocData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}

function DeleteTrackingToolDocData(fileMediaID, ttId, mdsdocId) {
    return webserviceWrapper.get(`/TrackingTool/DeleteTrackingToolDocData?fileMediaID=${fileMediaID}&trackingToolID=${ttId}&mdsdocId=${mdsdocId}`);
}

function SaveDocumentStatus(ttDocData) {
    return webserviceWrapper.post(
        `/TrackingTool/SaveDocumentStatus`,
        ttDocData,
        60000
    );
}

function ReSendMDSDoCForCorrection(trackingToolID, loggedInUserID, documentType) {
    return webserviceWrapper.get(`/TrackingTool/ReSendMDSDoCForCorrection?ttID=${trackingToolID}&loggedInUserID=${loggedInUserID}&documentType=${documentType}`);
}

function DownloadPOReadableFiles(historyData) {
    return webserviceWrapper.downloadBlobPost(`/TrackingTool/DownloadPOReadableFiles`, historyData, 600000);
}

function DownloadTrackingToolData(trackingToolList) {
    return webserviceWrapper.downloadBlobPost(`/TrackingTool/DownloadTrackingToolData`, trackingToolList, 1800000);
}

function DownloadAllTrackingToolData(trackingToolList) {
    return webserviceWrapper.downloadBlobPost(`/TrackingTool/DownloadAllTrackingToolData`, trackingToolList, 1800000);
}

function DownloadApiImport(apiImportData) {
    return webserviceWrapper.downloadBlobPost(`/TrackingTool/DownloadApiImport`, apiImportData, 600000);
}

function GetAllHazmatFromDB() {
    return webserviceWrapper.get(`/TrackingTool/GetAllHazmatDataFromDB`);
}

function CheckIfHazmatPresent(hazmatName) {
    return webserviceWrapper.get(`/Common/CheckIfHazmatPresent?hazmatName=${hazmatName}`);
}


function AddHazmatDataToDB(data) {
    return webserviceWrapper.post(
        `/TrackingTool/AddHazmatData`,
        data,
        60000
    );
}

function DeleteTTLineItemData(TTId, userTypeId, loggedInUserId) {
    return webserviceWrapper.get(`/TrackingTool/DeleteTTLineItemData?TTId=${TTId}&userTypeId=${userTypeId}&loggedInUserId=${loggedInUserId}`);
}

function CheckIfSupplierExist(supplierBranch, supplierEmail) {
    return webserviceWrapper.get(`/TrackingTool/CheckIfSupplierExist?supplierBranch=${supplierBranch}&supplierEmail=${supplierEmail}`);
}

function GetAllOOCLApiData(loggedInUserId, userTypeId) {
    return webserviceWrapper.get(`/TrackingTool/GetAllOOCLApiData?loggedInUserId=${loggedInUserId}&userTypeId=${userTypeId}`, 1800000);
}

function DeleteOOCLApiData() {
    return webserviceWrapper.get(`/TrackingTool/DeleteOOCLApiData`, 1800000);
}
